@import "../../node_modules/bootstrap/scss/_functions";
@import "theme/functions";

@import "../../node_modules/bootstrap/scss/mixins";
@import "theme/mixins";

@import "user-variables";
@import "theme/variables";
@import "../../node_modules/bootstrap/scss/variables";

// Your custom styles here
.choices__inner {
  min-height: unset !important;
  padding: 0 1rem !important;
  color: var(--falcon-input-color) !important;
}

#overlay{
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: .5;
  display:none;
  z-index: 99999;

  .loader {
    position: relative;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 70px;
    height: 70px;
    left:calc(50% - 35px);
    top:50%;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  .loader-message {
    position: relative;
    left: 0;
    top: calc(50% + 10px);
    text-align: center;
    width: 100%;
    font-size: 2em;
    color: white;
  }
}
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}